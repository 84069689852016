<template>
	<v-container fluid>
		<v-row>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="primary"
						icon="private-profile"
						title="# of private users"
						:value="localeInteger(total.privateAccounts)"
						small-value=""
						sub-icon=""
						sub-icon-color=""
						:sub-text="Math.round(total.privateAccounts / total.totalAccounts * 100) + '% of ' + localeInteger(total.totalAccounts) + ' accounts'"
						sub-text-color="text-primary"
				/>
			</v-col>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="primary"
						icon="public-profile"
						title="# of public users"
						:sub-text="Math.round(total.publicAccounts / total.totalAccounts * 100) + '% of ' + localeInteger(total.totalAccounts) + ' accounts'"
						:value="localeInteger(total.publicAccounts)"
				/>
			</v-col>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="primary"
						icon="pencil"
						title="# of posts"
						sub-text="Posts, reviews, articles, pledges"
						:value="localeInteger(total.posts)"
				/>
			</v-col>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="idea"
						icon="agree"
						title="# of agrees"
						sub-text="Only post agrees"
						:value="localeInteger(total.postAgrees)"
				/>
			</v-col>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="primary"
						icon="love"
						title="# of climate love"
						:sub-text="Math.round(total.love / total.reviews * 100) + '% of ' + localeInteger(total.reviews) + ' climate reviews'"
						:value="localeInteger(total.love)"
				/>
			</v-col>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="idea"
						icon="idea"
						title="# of climate idea"
						:sub-text="Math.round(total.idea / total.reviews * 100) + '% of ' + localeInteger(total.reviews) + ' climate reviews'"
						:value="localeInteger(total.idea)"
				/>
			</v-col>

			<v-col
					cols="12"
					sm="6"
					lg="3"
			>
				<material-stats-card
						color="warning"
						icon="warning"
						title="# of climate warning"
						:sub-text="Math.round(total.warning / total.reviews * 100) + '% of ' + localeInteger(total.reviews) + ' climate reviews'"
						:value="localeInteger(total.warning)"
				/>
			</v-col>

		</v-row>

		<v-row>

			<v-col
					cols="12"
			>
				<material-card
						class="card-tabs"
						color="primary"
				>
					<template v-slot:header>
						<v-tabs
								v-model="tabs"
								background-color="transparent"
								slider-color="white"
						>
							<v-tab>Latest level up</v-tab>
							<v-tab>All</v-tab>
							<v-tab>Love</v-tab>
							<v-tab>Idea</v-tab>
							<v-tab>Warning</v-tab>
						</v-tabs>
					</template>

					<v-tabs-items v-model="tabs">
						<v-tab-item :key="1">
							<v-data-table
								:headers="latestLevelUpHeaders"
								:loading="loading.tab1"
								:items="latestLevelUp"
								hide-default-footer
								@click:row="(o) => goToPost(o)"
								class="row-pointer"
							>
								<template v-slot:[`item.post.answered`]="{ item }">
									<span>{{ item.post.answered_at }}</span>
								</template>
								<template v-slot:[`item.post.review.type`]="{ item }">
									<svg-icon :icon="item.post.review.type" />
								</template>
								<template v-slot:[`item.reached_at`]="{ item }">
									{{ item.timestamp }}
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="2">
							<v-data-table
								:headers="top.headers"
								:items="getTopAll()"
								hide-default-footer
								@click:row="(o) => goToPost(o)"
								class="row-pointer"
							>
								<template v-slot:[`item.review.type`]="{ item }">
									<svg-icon :icon="item.review.type" />
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="3">
							<v-data-table
									:headers="top.headers"
									:items="top.love"
									hide-default-footer
									@click:row="(o) => goToPost(o)"
									class="row-pointer"
							>
								<template v-slot:[`item.review.type`]>
									<svg-icon icon="love" />
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="4">
							<v-data-table
									:headers="top.headers"
									:items="top.idea"
									hide-default-footer
									@click:row="(o) => goToPost(o)"
									class="row-pointer"
							>
								<template v-slot:[`item.review.type`]>
									<svg-icon icon="idea" />
								</template>
							</v-data-table>
						</v-tab-item>
						<v-tab-item :key="5">
							<v-data-table
									:headers="top.headers"
									:items="top.warning"
									hide-default-footer
									@click:row="(o) => goToPost(o)"
									class="row-pointer"
							>
								<template v-slot:[`item.review.type`]>
									<svg-icon icon="warning" />
								</template>
							</v-data-table>
						</v-tab-item>
					</v-tabs-items>
				</material-card>
			</v-col>

		</v-row>

	</v-container>
</template>

<script>
	import { globalMixIn } from '@/App'

	export default {
		data () {
			return {
				loading: {
					tab1: false,
				},
				latestLevelUp: [],
				latestLevelUpHeaders: [
					{
						text: `Reached at`,
						value: `reached_at`,
					},
					{
						text: `Answered at`,
						value: `post.answered_at`,
						align: `right`
					},
					{
						text: `Level`,
						value: `level`,
						align: `right`
					},
					{
						text: ``,
						value: `post.review.type`,
					},
					{
						text: `Title`,
						value: `post.title`
					},
					{
						text: `Author`,
						value: `post.author.at_name`,
					},
					{
						text: `Recipient`,
						value: `post.recipient.at_name`,
					},
				],
				total: {
					postAgrees: null,
					posts: null,
					reviews: {
						count: null,
						types: {
							love: null,
							warning: null,
							idea: null,
						}
					}
				},
				top: {
					headers: [
						{
							sortable: false,
							text: `Agrees`,
							value: `totalAgrees`,
							align: `right`,
							width: 40,
						},
						{
							sortable: false,
							text: `Level`,
							value: `level`,
							align: `right`,
							width: 40,
						},
						{
							sortable: false,
							text: ``,
							value: `review.type`,
							width: 40,
						},
						{
							sortable: false,
							text: `Title`,
							value: `title`
						},
					],
					love: [],
					idea: [],
					warning: [],
				},
				tabs: 0,
			}
		},
		mixins: [globalMixIn],
		methods: {
			getTopAll() {
				let topAll = [...this.top.love, ...this.top.idea, ...this.top.warning]
				return topAll.sort((a, b) => b.agrees - a.agrees).slice(0,10)
			},
			getTopClimateActions(type) {
				this.$httpInt.get(`/v2/app/posts/most-agrees`, { params : { type } })
					.then(res => this.top[type] = res.data.result)
			},
			getStatisticsTotal() {
				this.$httpInt.get(`/v2/statistics/total-private-profiles`)
					.then(res => {
						this.total.totalAccounts = (this.total.totalAccounts || 0) + res.data.result.count
						this.total.privateAccounts = res.data.result.count
					})

				this.$httpInt.get(`/v2/statistics/total-public-profiles`)
					.then(res => {
						this.total.totalAccounts = (this.total.totalAccounts || 0) + res.data.result.count
						this.total.publicAccounts = res.data.result.count
					})

				this.$httpInt.get(`/v2/statistics/total-posts`)
					.then(res => this.total.posts = res.data.result.count)

				this.$httpInt.get(`/v2/statistics/total-post-agrees`)
					.then(res => this.total.postAgrees = res.data.result.count)

				this.$httpInt.get(`/v2/statistics/total-posts?type=review`)
					.then(res => this.total.reviews = res.data.result.count)

				this.$httpInt.get(`/v2/statistics/total-posts?type=love`)
					.then(res => this.total.love = res.data.result.count)

				this.$httpInt.get(`/v2/statistics/total-posts?type=warning`)
					.then(res => this.total.warning = res.data.result.count)

				this.$httpInt.get(`/v2/statistics/total-posts?type=idea`)
					.then(res => this.total.idea = res.data.result.count)
			},
			goToPost(item) {
				this.$router.push({name: `Post`, params: {id: item.post && item.post._id || item._id || item.id}})
			},
			getLatestLevelUp() {
				this.loading.tab1 = true
				this.$httpInt.get(`/v2/app/posts/recent-level-ups`)
					.then(res => this.latestLevelUp = res.data.result)
					.finally(() => this.loading.tab1 = false)
			},
		},
		mounted() {
			this.getLatestLevelUp()
			this.getTopClimateActions(`love`)
			this.getTopClimateActions(`idea`)
			this.getTopClimateActions(`warning`)
			this.getStatisticsTotal()
		},
		name: `CommunityOverview`,
	}
</script>
